<template>
  <div id="main">
    <iframe width="100%" height="100%" src="https://ylsp.tv" allowfullscreen="true" frameborder="0"></iframe>
    <div class="main-gg" v-if="view">
      <div class="close" @click="view = false">
        <i class="el-icon-circle-close"></i>
      </div>
      <img class="main-gg-img" src="../assets/img/favicon.png" alt="">
      <h3 class="main-gg-title">Kuilld.com</h3>
      <div class="main-gg-dis">KuilldVPN高速畅游全球网络，安全顺畅浏览社交媒体，以及主流流媒体内容平台，1080P随意拖拽,4K清晰度极速不卡顿。
        提供首屈一指，业界领先的VPN服务，极速体验，告别无止尽的龟速等待。突破天际，让您无忧无虑畅游全球网络！</div>
        <div class="button">
          <a style="color: aliceblue;" href="https://www.kuilld.com" target="_blank" rel="noopener noreferrer">免费体验</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      view: true,
    };
  },

  components: {
  },

  methods: {

  }
}
</script>

<style scoped>
#main {
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: rgba(0,0,0,0.88);
  background-image: url('../assets/img/dongtu.gif');
  background-repeat: no-repeat; /* 防止背景图片重复 */
  background-position: center center; /* 水平垂直居中 */
  background-size: 220px;
}

.main-gg{
  position: fixed;
  z-index: 100000;
  background-color: rgba(0,0,0,0.5);
  bottom: 10%;
  right: 20px;
  width: 260px;
  height: 400px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  backdrop-filter: blur(10px); 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close{
  position: absolute;
  top: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 28px;
  left: 0px;
  cursor: pointer;
}

.main-gg-img{
  width: 60%;
  margin-top: 20px;
}

.main-gg-title{
  color: rgba(255,255,255,0.7);
  font-size: 20px;
  margin-top: 10px
}
.main-gg-dis{
  color: rgba(255,255,255,0.8);
  font-size: 13px;
  margin-top: 10px;
  line-height: 20px;
  padding: 10px;
}

.button{
    padding: 10px 20px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    background-image: linear-gradient(25deg, #2600fc, #ff00ea);
}


</style>